<template>
  <div class="podcast-container" :class="{ mobile: isMobile }">
    <div v-if="!isMobile">
      <img
        class="foto"
        ref="picture"
        src="@/assets/pictures/other/07c.jpg"
        alt="Zdjęcie mikrofonu"
      />
    </div>

    <div ref="info" class="info" :class="{ mobile: isMobile }">
      <div ref="header" class="header-container" :class="{ mobile: isMobile }">
        <div class="circle" ref="lottieContainer"></div>
        <h2 class="content_header">Podcast</h2>
      </div>

      <div v-if="isMobile">
        <img
          class="foto"
          :class="{ mobile: isMobile }"
          ref="picture"
          src="@/assets/pictures/other/07c.jpg"
          alt="Zdjęcie mikrofonu"
        />
      </div>

      <div ref="podcast">
        <p></p>
        <h5>Najnowsze odcinki:</h5>

        <div class="podcasts-list" :class="{ mobile: isMobile }">
          <iframe
            title="spotify 2 podcast"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/episode/6N8kUxUDmBoXKPD4GvveL1?utm_source=generator&theme=0"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>

          <iframe
            title="spotify 1 podcast"
            style="border-radius: 12px"
            src="https://open.spotify.com/embed/show/2zfA6OiWBZGZtF4jJKUehV?utm_source=generator&theme=0"
            width="100%"
            height="152"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>

        <p class="bold">Zobacz wszystkie odcinki na:</p>

        <div class="icons" :class="{ mobile: isMobile }">
          <div class="icon-container" :class="{ mobile: isMobile }">
            <a
              href="https://open.spotify.com/show/2zfA6OiWBZGZtF4jJKUehV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="logo spotify"
                class="icon"
                :class="{ mobile: isMobile }"
                src="@/assets/elements/spotify-logo.svg"
              />
              <div class="icon-text" :class="{ mobile: isMobile }">Spotify</div>
            </a>
          </div>

          <div class="icon-container" :class="{ mobile: isMobile }">
            <a
              href="https://podcasts.apple.com/us/podcast/od-stresu-do-gastrobiznesu/id1742209704"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                class="icon"
                alt="logo apple podcasts"
                :class="{ mobile: isMobile }"
                src="@/assets/elements/Podcasts_(iOS).svg"
              />
              <div class="icon-text" :class="{ mobile: isMobile }">
                Apple Podcast
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  metaInfo: {
    title:
      'Nawigacja Mobilna - Odkryj Nasze Szkolenia, Podcasty, Blogi i Więcej!',
    meta: [
      {
        name: 'description',
        content:
          'Użyj naszej intuicyjnej nawigacji mobilnej, aby łatwo eksplorować różne sekcje naszej strony. Znajdź wszystko, od szkoleń, przez podcasty, po blogi i kontakt. Zobacz, co mamy do zaoferowania, gdziekolwiek jesteś i kiedykolwiek chcesz.'
      },
      {
        name: 'keywords',
        content:
          'mobilna nawigacja, szkolenia online, podcasty, blogi, kontakt, intuicyjna nawigacja, dostępność'
      }
    ]
  },
  data() {
    return {
      isHeaderVisible: false
    }
  },
  mounted() {
    this.observeElement(this.$refs.header, 'isVisible1', this.loadAnimation)
    this.setupObserver()
  },
  methods: {
    setupObserver() {
      const alwaysVisibleElement = this.$refs.podcast // referencja do elementu, który jest zawsze widoczny
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !this.isMobile) {
              this.activateAnimations()
            }
          })
        },
        { threshold: 0.1 }
      )

      observer.observe(alwaysVisibleElement)
    },

    activateAnimations() {
      const elementsToAnimate = [this.$refs.info]

      elementsToAnimate.forEach(el => {
        if (el) {
          el.classList.add('animated')
        }
      })
    },

    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000)
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },

    loadAnimation() {
      const anim = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/circles/05.json'
      })

      anim.setSpeed(2)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.header-container.mobile {
  margin-bottom: 2rem;
}

.podcasts-list {
  width: 80%;
}

.podcasts-list.mobile {
  width: 100%;
}
.podcast-container {
  margin-top: 5rem;
  padding: 0rem;
  display: grid;
  gap: 6rem;
  grid-template-columns: 2fr 3fr;
  align-items: center;
}

.podcast-container.mobile {
  margin-top: 0rem;
  display: flex;
  align-items: center;
}

.foto {
  width: 25rem;
  height: 25rem;
  object-fit: cover;
  object-position: 30% 80%;
  border-radius: 5px;
}

.foto.mobile {
  width: 100%;
}

.info.animated {
  animation: fadeIn 1s forwards 0.5s;
}

p {
  text-indent: 0em;
  margin-top: 2rem;
}

.icon {
  width: 2rem;
  margin-right: 0px;
  margin-left: 0px;
  padding: 0px;
}

.icon.mobile {
  width: 3rem;
}

.icons {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  margin-left: 0rem;
  padding: 0px;
  grid-area: icons;
  max-width: 10rem;
}
.icons.mobile {
  margin-left: auto;
  margin-right: auto;
  max-width: 15rem;
}

.icon-text {
  color: $gray;
}

.info {
  opacity: 0;
  position: relative;
}

.info.mobile {
  opacity: 1;
}
</style>
