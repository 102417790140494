import { createStore } from 'vuex'

import responsiveModule from './modules/responsive/index.js'
import articlesModule from './modules/articles/index.js'
import sectionModule from './modules/section/index.js'

const store = createStore({
  namespaced: true,
  modules: {
    responsive: responsiveModule,
    articles: articlesModule,
    section: sectionModule
  }
})

export default store
