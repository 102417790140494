<template>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-content">
      <h4>Polityka Prywatności i Cookies</h4>
      <p>Prosimy o zapoznanie się z naszą polityką prywatności i polityką cookies.</p>
      <a href="https://odstresudogastrobiznesu.pl/polityka-prywatno%C5%9Bci-progres.pdf" target="_blank" rel="noopener noreferrer">Polityka Prywatności</a>
      <a href="https://odstresudogastrobiznesu.pl/polityka-cookies-progres.pdf" target="_blank" rel="noopener noreferrer">Polityka Cookies</a>
      <button @click="acceptPolicy">Zapoznałem się i Akceptuję</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
    };
  },
  mounted() {
    if (!localStorage.getItem('policyAccepted')) {
      this.showPopup = true;
    }
  },
  methods: {
    acceptPolicy() {
      this.showPopup = false;
      localStorage.setItem('policyAccepted', true);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/main.scss';
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: $white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 30rem;
  width: 80%;
}

.popup-content h2 {
  margin-top: 0;
}

.popup-content a {
  display: block;
  margin: 10px 0;
  color: $main-color;
  text-decoration: none;
  font-weight: bold;
}

.popup-content a:hover {
  text-decoration: underline;
}

.popup-content button {
  background-color: $main-color;
  margin-top: 1rem;
  
  padding: 0.6rem 1.5rem;
  padding-top: .75rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;

  z-index: 5;

}
</style>
