<template>
  <div ref="trigger" class="hero-section-background">
    <div class="hero-background">
      <div class="hero">
        <SubscriptionPopup ref="subscriptionPopup" />
        <div>
          <h1 ref="header" class="hero-h1" :class="{ mobile: isMobile }">
            Od Stresu do Gastrobiznesu
          </h1>
          <div class="hero-h4-background" :class="{ mobile: isMobile }"></div>
          <div class="hero-h4-background2" :class="{ mobile: isMobile }"></div>
          <h4 ref="h4" class="hero-h4" :class="{ mobile: isMobile }">
            Stwórz naljepszą wersję swojej firmy!
          </h4>

          <h5 ref="h5" class="hero-h5" :class="{ mobile: isMobile }">
            Wprowadzę Cię w tajniki zarządzania lokalem gastronomicznym w taki
            sposób, aby był najlepszy dla Twoich gości, Twojej załogi, oraz dla
            Ciebie.
          </h5>

          <div
            v-if="!isMobile"
            ref="signature"
            class="signature"
            :class="{ mobile: isMobile }"
          >
            Martyna Więckowiak
          </div>

          <img
            :class="{ mobile: isMobile }"
            src="@/assets/logo/04ws.png"
            class="hero-logo"
            alt="Logo firmy"
          />

          <button
            ref="heroButton"
            @click="openSubscriptionPopup"
            class="hero-button"
            :class="{ mobile: isMobile }"
          >
            Zapisz się do newslettera!
          </button>
        </div>
        <div>
          <div class="hero-menu" :class="{ mobile: isMobile }">
            <span v-if="!isMobile"
              class="nav-link"
              :class="{ mobile: isMobile }"
              @click="this.$emit('scroll-to', 'remote-services')"
              active-class="active"
              >Usługi Online</span
            >
            <span v-else
              class="nav-link"
              :class="{ mobile: isMobile }"
              @click="this.$emit('scroll-to', 'remote-services')"
              active-class="active"
              >Usługi</span
            >
            <span
              class="nav-link"
              :class="{ mobile: isMobile }"
              @click="this.$emit('scroll-to', 'courses')"
              active-class="active"
              >Szkolenia</span
            >
            <span
              class="nav-link"
              :class="{ mobile: isMobile }"
              @click="this.$emit('scroll-to', 'podcast')"
              active-class="active"
              >Podcast</span
            >

            <span
              v-if="!isMobile"
              class="nav-link"
              :class="{ mobile: isMobile }"
              @click="this.$emit('scroll-to', 'blog')"
              active-class="active"
              >Blog</span
            >

            <span
              class="nav-link"
              :class="{ mobile: isMobile }"
              @click="this.$emit('scroll-to', 'contact')"
              active-class="active"
              >Kontakt</span
            >
          </div>
        </div>
        <img
          ref="picture"
          class="hero-picture"
          src="@/assets/pictures/no-background/04brc.png"
          :class="{ mobile: isMobile }"
          alt="Obraz główny"
        />

        <div class="hero-menu-line" :class="{ mobile: isMobile }"></div>
        >
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionPopup from './elements/SubscriptionPopup.vue'

export default {
  name: 'HeroSection',
  metaInfo: {
    title: 'Od Stresu do Gastrobiznesu - Rozwijaj swój lokal z nami!',
    meta: [
      {
        name: 'description',
        content:
          'Poznaj nasze unikalne podejście do zarządzania lokalem gastronomicznym. Oferujemy szkolenia, podcasty i bogate zasoby wiedzy, które pomogą Ci stworzyć najlepszą wersję swojego biznesu. Zapisz się do naszego newslettera i odkryj nowe możliwości!'
      },
      {
        name: 'keywords',
        content:
          'zarządzanie restauracją, gastrobiznes, szkolenia gastronomiczne, podcasty o gastronomii, rozwój firmy, newsletter'
      }
    ]
  },
  components: {
    SubscriptionPopup
  },
  data() {
    return {
      intervalId: null
    }
  },
  methods: {
    openSubscriptionPopup() {
    this.$refs.subscriptionPopup.openPopup();



  },
    emitElement() {
      this.$emit('element-emitted', this.$refs.childElement)
    },

    setupObserver() {
      const alwaysVisibleElement = this.$refs.trigger // referencja do elementu, który jest zawsze widoczny
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.activateAnimations()
            }
          })
        },
        { threshold: 0.1 }
      )

      observer.observe(alwaysVisibleElement)
    },

    activateAnimations() {
      const elementsToAnimate = [
        this.$refs.header,
        this.$refs.h4,
        this.$refs.h5,
        this.$refs.signature,

        this.$refs.picture
      ]

      elementsToAnimate.forEach(el => {
        if (el) {
          el.classList.add('animated')
        }
      })
    },

    triggerAnimation() {
      const button = this.$refs.heroButton
      if (button) {
        button.classList.add('shake-animation')
        setTimeout(() => {
          button.classList.remove('shake-animation')
        }, 500)
      }
    }
  },
  mounted() {
    this.setupObserver()

    this.setupObserver()

    setTimeout(() => {
      this.triggerAnimation()
      this.intervalId = setInterval(() => {
        this.triggerAnimation()
      }, 10500)
    }, 5000)
  },
  unmounted() {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes apear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes leftAndBounce {
  from {
    opacity: 0;
    transform: translateX(-130px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes right {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes painting {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-3px);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateX(3px);
  }
}

.shake-animation {
  animation: shake 0.5s ease-in-out;
}

.hero-section-background {
  background-image: url('./../assets/pictures/restaurant-pictures/austin-gardner-NiFtfpp-AXk-unsplashc.avif');
  background-size: cover;
  background-position: center;
  z-index: -5;
}

.hero-background {
  background-image: linear-gradient(
    to top right,
    rgba($secendary-color-dark, 1),
    rgba($secendary-color-dark, 0.5)
  );
}

.hero {
  position: relative;
  display: grid;
  grid-template-columns: 2fr 3fr;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5rem;
  height: 42rem;
  max-width: $page-width;
  padding-top: 1rem;
}

.hero-logo {
  position: absolute;
  width: 4rem;
  top: 2rem;
  left: 2rem;
  z-index: 0;
}

.hero-logo.mobile {
  top: 1.5rem;
  left: 0.5rem;
  width: 2rem;
}

.hero-picture {
  max-height: 37rem;
  position: absolute;
  right: 0rem;
  bottom: 0rem;
  z-index: 1;
  opacity: 0;
}

.hero-picture.mobile {
  max-height: 28rem;
}

.hero-picture.animated {
  animation: right 1s forwards 2s;
}

.hero-h1 {
  font-family: 'Roboto';
  font-weight: 900;
  position: absolute;
  top: 10rem;
  left: 2rem;
  padding-left: 0rem;
  z-index: 3;
  opacity: 0;

  color: $white;
  text-transform: uppercase;
}

.hero-h1.animated {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}

.hero-h1.mobile {
  top: 4rem;
  left: 1rem;
}

.hero-text-background {
  position: absolute;
  background-color: rgba($color: $gray, $alpha: 0);
  top: 11rem;
  left: 0rem;
  width: 42rem;
  height: 30rem;
}

.hero-h2 {
  font-family: 'Roboto';
  font-weight: 900;
  position: absolute;
  top: 12rem;
  left: 2rem;
  z-index: 3;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  color: $white;
  text-transform: uppercase;
}

.hero-h4 {
  font-family: 'Roboto-bold';
  position: absolute;
  top: 19rem;
  left: 2rem;
  z-index: 6;
  max-width: 40rem;
  opacity: 0;

  color: $white;
  text-decoration: underline;
  text-underline-offset: 15px;
  text-decoration: underline 0.1em rgba($main-color, 1);
}

.hero-h4.animated {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

.hero-h4.mobile {
  top: 13rem;
  font-size: 1.8rem;
  max-width: 15rem;
  left: 1rem;
  text-underline-offset: 5px;
}
.hero-h5 {
  font-family: 'Roboto-bold';
  position: absolute;
  top: 24rem;
  left: 2rem;
  z-index: 3;
  max-width: 40rem;
  opacity: 0;
  color: $white;
}

.hero-h5.mobile {
  left: 1rem;
  top: 24rem;
  max-width: 14rem;
  font-size: 1.3rem;
}

.hero-h5.animated {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.signature {
  position: absolute;
  color: $white;
  top: 32rem;
  left: 28rem;
  font-size: $text-large;
  font-family: 'Courgette';
  opacity: 0;
}

.signature.animated {
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1.2s;
}

.hero-button {
  background-color: $main-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  position: absolute;
  bottom: 4rem;
  left: 2rem;

  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  font-size: $text-large;
  box-shadow: 2px 2px 2px 0px $main-color-dark;

  border: none;
  cursor: pointer;
  color: $gray;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.1;
  }
}

.hero-button.mobile {
  bottom: 1rem;
  font-size: 1rem;
  padding: 0.3rem 1rem;
  left: 4rem;
}

.hero-menu {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  margin-right: 4rem;
}

.hero-menu.mobile {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  margin-right: 0.5rem;
}

.nav-link {
  font-family: 'Roboto-bold';
  cursor: pointer;
  margin-left: 3rem;
  white-space: nowrap;
  align-self: center;
  color: white;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration: underline 0.4em rgba($main-color, 0);
  transition: text-decoration-color 1000ms;
  text-transform: uppercase;
  &:hover {
    text-decoration: underline 0.4em rgba($main-color, 1);
  }
}

.nav-link.mobile {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.hero-menu-line {
  position: absolute;
  top: 7rem;
  width: 100%;
  height: 2px;
  background-color: $main-color;
}

.hero-menu-line.mobile {
  top: 5rem;
}
</style>
