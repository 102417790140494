<template>
  <nav class="mobile-navigation" :class="{ hidden: isMenuHidden }">
    <div class="hero-menu">
      <div
        class="nav-link"
        @click="changeSection('hero', '/')"
        active-class="active"
      >
        <img class="icon" src="@/assets/icons/house.svg" />
        <div class="icon-text">Główna</div>
      </div>
      <div
        class="nav-link"
        @click="changeSection('remote-services', '/')"
        active-class="active"
      >
        <img class="icon" src="@/assets/icons/services.svg" />
        <div class="icon-text">Usługi </div>
      </div>
      <div
        class="nav-link"
        @click="changeSection('courses', '/')"
        active-class="active"
      >
        <img class="icon" src="@/assets/icons/courses.svg" />
        <div class="icon-text">Szkolenia</div>
      </div>
      <div
        class="nav-link"
        @click="changeSection('podcast', '/')"
        active-class="active"
      >
        <img class="icon" src="@/assets/icons/podcast.svg" />
        <div class="icon-text">Podcast</div>
      </div>

      <div
        class="nav-link"
        @click="changeSection('blog', '/')"
        active-class="active"
      >
        <img class="icon" src="@/assets/icons/article.svg" />
        <div class="icon-text">Blog</div>
      </div>

      <div
        class="nav-link"
        @click="changeSection('contact', '/')"
        active-class="active"
      >
        <img class="icon" src="@/assets/icons/contact.svg" />
        <div class="icon-text">Kontakt</div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  metaInfo: {
    title:
      'Nawigacja Mobilna - Odkryj Nasze Szkolenia, Podcasty, Blogi i Więcej!',
    meta: [
      {
        name: 'description',
        content:
          'Użyj naszej intuicyjnej nawigacji mobilnej, aby łatwo eksplorować różne sekcje naszej strony. Znajdź wszystko, od szkoleń, przez podcasty, po blogi i kontakt. Zobacz, co mamy do zaoferowania, gdziekolwiek jesteś i kiedykolwiek chcesz.'
      },
      {
        name: 'keywords',
        content:
          'mobilna nawigacja, szkolenia online, podcasty, blogi, kontakt, intuicyjna nawigacja, dostępność'
      }
    ]
  },
  data() {
    return {
      isMenuHidden: true
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  },

  methods: {
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    },
    onWindowScroll() {
      const scrollThreshold = 200
      const currentScrollPos = window.pageYOffset
      const isScrollingUp = currentScrollPos < this.prevScrollPos

      if (isScrollingUp && this.isMenuHidden) {
        this.isMenuHidden = false
      } else if (
        !isScrollingUp &&
        currentScrollPos > scrollThreshold &&
        !this.isMenuHidden
      ) {
        this.isMenuHidden = true
      }

      this.prevScrollPos = currentScrollPos
    }
  },
  mounted() {
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onWindowScroll)
  }
}
</script>

<style scoped lang="scss">
@import '~@/main.scss';

.mobile-navigation {
  position: fixed;
  bottom: 0;
  transition: transform 0.3s ease;
  width: 100%;
  height: 4rem;
  background-color: rgba($secendary-color-dark, 0.9);
  z-index: 1000;
  padding-top: 0.5rem;
}

.mobile-navigation.hidden {
  transform: translateY(+100%);
  max-width: 100%;
}

#menu {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
}

.hero-menu {
  display: flex;
  justify-content: flex-end;
  flex-basis: 100%;
  margin-right: 0rem;
}

.nav-link {
  width: 4rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  font-family: 'Roboto-bold';
  cursor: pointer;
  white-space: nowrap;
  align-self: center;
  color: white;
  text-decoration: underline;
  text-underline-offset: 1rem;
  text-decoration: underline 0.4em rgba($main-color, 0);
  transition: text-decoration-color 1000ms;
  text-transform: uppercase;

  text-align: center;
}

.icon {
  height: 2rem;
  width: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.icon-text {
  color: $white;
  font-size: 0.7rem;
}
</style>
