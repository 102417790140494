export default {
  state: {
    section: 'default'
  },
  mutations: {
    setSection(state, newSection) {
      state.section = newSection
    }
  },
  actions: {
    updateSection({ commit }, newSection) {
      commit('setSection', newSection)
    }
  }
}
