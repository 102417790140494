export default {
  namespaced: true,
  state() {
    return {
      isMobile: window.innerWidth < 768
    }
  },
  getters: {},
  mutations: {
    updateIsMobile(state) {
      state.isMobile = window.innerWidth < 768
    }
  },
  actions: {
    checkIsMobile(context) {
      const isMobile = window.innerWidth < 768
      context.commit('updateIsMobile', isMobile)
    }
  },
  modules: {}
}
