<template>
  <div class="blog-container" :class="{ mobile: isMobile }">
    <div ref="info" class="info" :class="{ mobile: isMobile }">
      <div ref="header" class="header-container">
        <div class="circle-short" ref="lottieContainer"></div>
        <h2 class="content_header">Blog</h2>
      </div>
      <div ref="blog">
        <h5 class="article-header" :class="{ mobile: isMobile }">
          Najnowsze artykuły:
        </h5>
      </div>
    </div>
    <div></div>
    <div ref="article1" class="article-shortcud" :class="{ mobile: isMobile }">
      <h6>{{ articles[0].art_header }}</h6>
      <img
        v-if="isMobile"
        class="foto"
        alt="zdjęcie do trzeciego artykułu"
        :class="{ mobile: isMobile }"
        ref="picture"
        :src="articles[0].art_img"
      />
      <p v-html="truncateText(articles[0].art_shortcut, 500)"></p>
      <div class="link-container">
        <div
          class="more"
          
          @click="selectArticle(articles[0].art_id)"
        >
          <div class="text-link">czytaj dalej...</div>
        </div>
      </div>
    </div>

    <img
      v-if="!isMobile"
      alt="Zdjęcie zawieszania zamówienia na tablicy"
      class="foto"
      ref="picture"
      :src="articles[0].art_img"
    />

    <div ref="article2" class="article-shortcud" :class="{ mobile: isMobile }">
      <h6>
        {{ articles[1].art_header }}
      </h6>
      <div v-if="isMobile">
        <img
          class="foto"
          :class="{ mobile: isMobile }"
          ref="picture"
          :src="articles[1].art_img"
          alt="Zdjęcie artykułu 01"
        />
      </div>
      <p v-html="truncateText(articles[1].art_shortcut, 500)"></p>
      <div class="link-container">
        <div
          class="more"
          to="/articles/01"
          @click="selectArticle(articles[1].art_id)"
        >
          <div class="text-link">czytaj dalej...</div>
        </div>
      </div>
    </div>

    <div v-if="!isMobile">
      <img
        class="foto"
        alt="zdjęcie do drugiego artykułu"
        ref="picture"
        :src="articles[1].art_img"
      />
    </div>

    <div class="link-container">
      <div
        class="more"
        to="/articles"
        @click="changeSection('top', '/articles')"
      >
        <div class="text-link">Zobacz wszyskie artykuły...</div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import { mapGetters } from 'vuex';

export default {
  name: 'BlogContainer',
  metaInfo: {
    title: 'Blog - Najnowsze Artykuły o Gastronomii i Zarządzaniu Restauracją',
    meta: [
      {
        name: 'description',
        content:
          'Odkryj najnowsze trendy i strategie w gastronomii. Przeczytaj nasze artykuły, które pomogą Ci dostosować się do zmieniającego się świata restauracji, zwiększyć sprzedaż i osiągnąć sukces w branży.'
      },
      {
        name: 'keywords',
        content:
          'gastronomia, zarządzanie restauracją, zwiększanie sprzedaży w restauracji, nowe trendy w gastronomii, blog gastronomiczny'
      }
    ]
  },
  data() {
    return {
      isHeaderVisible: false
    }
  },
  mounted() {
    this.observeElement(this.$refs.header, 'isVisible1', this.loadAnimation)
    this.setupObserver()
  },
  methods: {
    async selectArticle(artId) {
      await this.$router.push({ name: 'Article', params: { id: artId } });
      await this.$store.dispatch('updateSection', 'top')
      await this.$store.dispatch('updateSection', 'defoult')
    },
    async changeSection(newSection, page) {
      await this.$router.push(page)
      await this.$store.dispatch('updateSection', newSection)
      await this.$store.dispatch('updateSection', 'defoult')
    },
    setupObserver() {
      const alwaysVisibleElement = this.$refs.blog // referencja do elementu, który jest zawsze widoczny
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !this.isMobile) {
              this.activateAnimations()
            }
          })
        },
        { threshold: 0.1 }
      )

      observer.observe(alwaysVisibleElement)
    },

    activateAnimations() {
      const elementsToAnimate = [
        this.$refs.info,
        this.$refs.article1,
        this.$refs.article2
      ]

      elementsToAnimate.forEach(el => {
        if (el) {
          el.classList.add('animated')
        }
      })
    },

    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000)
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },

    loadAnimation() {
      const anim = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/circles/05.json'
      })

      anim.setSpeed(2)
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    },
    ...mapGetters('articles', ['getLatestArticles']),
      articles() {
        return this.getLatestArticles;
      },
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

h6 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.header-container {
}

.article-shortcud {
  margin-bottom: 2rem;
  opacity: 0;
}

.article-shortcud.mobile {
  opacity: 1;
}
.article-shortcud.animated {
  animation: fadeIn 1s forwards 0.5s;
}

.article-header.mobile {
  margin-bottom: -1rem;
}

.blog-container {
  margin-top: 10rem;
  padding: 0rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: 3fr 2fr;
  align-items: center;
}
.blog-container.mobile {
  grid-template-columns: 1fr;
}

.element4 {
  order: 2;
}

.foto {
  width: 15rem;
  height: 15rem;
  object-fit: cover;
  border-radius: 5px;
  margin-left: 5rem;
}

.foto.mobile {
  margin-left: 2rem;
  margin-top: 1rem;
}
.info.animated {
  animation: fadeIn 1s forwards 0.5s;
}

p {
  text-indent: 1.5em;
  margin-top: 2rem;
  max-width: 32rem;
}

.info {
  position: relative;
  opacity: 0;
}
.button-container {
  margin-top: 5rem;
  width: 100%;
  text-align: center;
}

.bottm-link {
  left: 0rem;
  bottom: -2rem;
}

.more {
  text-decoration: none;
}

.info.mobile {
  opacity: 1;
}
</style>
