<template>
  <div class="remote-services-container" :class="{ mobile: isMobile }">
    <div ref="header" class="header-container" :class="{ mobile: isMobile }">
      <div class="horizontal-circle" :class="{ mobile: isMobile }" ref="lottieContainer"></div>
      <h2 class="content_header">Menedżer online</h2>
    </div>

    <h5 class="instructions-header" :class="{ mobile: isMobile }">Jak rozpocząć współpracę?</h5>


    <div  class="instructions" :class="{ mobile: isMobile }">
      <div class="instruction-img" :class="{ mobile: isMobile }"><img src='./../assets/elements/tick.svg'></div>
      <div class="instruction-text" :class="{ mobile: isMobile }"><p><strong>Wybierasz pakiet</strong>, który Cię interesuje </p></div>
      <div class="instruction-img" :class="{ mobile: isMobile }"><img src='./../assets/elements/tick.svg'></div>
      <div class="instruction-text" :class="{ mobile: isMobile }"><p><strong>Zamawiasz konsultację.</strong> Omawiamy Twoje potrzeby i oczekiwania dotyczące wsparcia administracyjnego lub menedżerskiego. Ustalamy zakres działań.</p></div>
      <div class="instruction-img" :class="{ mobile: isMobile }"><img src='./../assets/elements/tick.svg'></div>
      <div class="instruction-text" :class="{ mobile: isMobile }"><p><strong>Podpisujemy umowy.</strong> Zgodnie z tym co ustaliliśmy, podpisujemy umowę współpracy oraz NDA i RODO. To daje Ci poczucie bezpieczeństwa, że wszystkie przekazane nam dane pozostają bezpieczne i poufne.</p></div>
      <div class="instruction-img" :class="{ mobile: isMobile }"><img src='./../assets/elements/tick.svg'></div>
      <div class="instruction-text" :class="{ mobile: isMobile }"><p><strong>Działamy.</strong> Od razu przystępujemy do realizacji zadań.</p></div>
      <div class="instruction-img" :class="{ mobile: isMobile }"><img src='./../assets/elements/tick.svg'></div>
      <div class="instruction-text" :class="{ mobile: isMobile }"><p><strong>Cieszysz się wolnym czasem,</strong> patrzysz jak Twój biznes rośnie i możesz skupić się na rzeczach, które wymagają Twojej uwagi.</p></div>

    </div>

  

    <h5 class="instructions-header" :class="{ mobile: isMobile }">Co zyskasz?</h5>
    <div class="services-cards-container" :class="{ mobile: isMobile }">
        <div v-for=" benefit, key in benefits" :key="key" class="cell">
        <img class="benefits-icon" :src=benefit.img> 
        <div class="card" :class="{ mobile: isMobile }" >
          <p class="benefits-header"><strong>{{ key }}</strong></p>     
          <p>{{ benefit.text }}</p>

        </div>
        </div>
      </div>

    <div class="services-grid" :class="{ mobile: isMobile }">
      <div class="service-card">
        <h5>Usługi<br> Administracyjne</h5>
        <div class="card-line" :class="{ mobile: isMobile }"></div>
        <h6 class="h6">Jeżeli:</h6>
        <ul>
          <li>Przytłaczają Cię codzienne obowiązki administracyjne i brak czasu na rozwój biznesu.</li>
          <li>Potrzebujesz wsparcia w zarządzaniu dokumentacją i rozliczeniami, by skupić się na strategii restauracji.</li>
          <li>Nie lubisz prac biurowych i wolisz skupić się na tym, co naprawdę lubisz robić.</li>
          <li>Masz problemy z nadążaniem za zadaniami i potrzebujesz dodatkowej pomocy.</li>
          <li>Chcesz więcej czasu na kreatywne myślenie i rozwój nowych pomysłów dla biznesu.</li>
          <li>Szukasz elastycznego rozwiązania dostosowanego do zmieniających się potrzeb bez zatrudniania pracownika na pełen etat.</li>

        </ul>
        <button
          class="services-button"
          :class="{ mobile: isMobile }"
          @click="selectService('asystent')"
        >
          Dowiedz się więcej!
        </button>
      </div>
      <div class="service-card menager">
        <h5>Usługi<br> Menedżerskie</h5>
        <div class="card-line" :class="{ mobile: isMobile }"></div>
        <h6 class="h6-menager">Jeżeli:</h6>
        <ul>
          <li>Potrzebujesz wsparcia w strategicznym zarządzaniu lokalem i zwiększeniu efektywności operacyjnej.</li>
          <li>Chcesz zoptymalizować koszty i poprawić rentowność biznesu.</li>
          <li>Planujesz rozwój oferty i potrzebujesz analizy rynku oraz wsparcia w wdrażaniu nowych produktów.</li>
          <li>Zmagasz się z zarządzaniem zespołem i potrzebujesz profesjonalnego wsparcia.</li>
          <li>Chcesz zwiększyć satysfakcję klientów przez lepsze zarządzanie jakością i procesami.</li>
          <li>Potrzebujesz wsparcia w organizacji i planowaniu wydarzeń specjalnych oraz eventów.</li>

        </ul>
        <button
          class="services-button-menager"
          :class="{ mobile: isMobile }"
          @click="selectService('menager')"
        >
          Dowiedz się więcej!
        </button>
      </div>
      <div class="service-card">
        
        <h5>Oferta<br> Indywidualna</h5>
        <div class="card-line" :class="{ mobile: isMobile }"></div>
        <h6 class="h6">Jeżeli:</h6>
        <ul>
          <li>Potrzebujesz spersonalizowanego podejścia dostosowanego do Twoich unikalnych wymagań biznesowych.</li>
          <li>Chcesz, aby rozwiązania były specjalnie zaprojektowane z myślą o Twojej restauracji.</li>
          <li>Szukasz indywidualnego wsparcia w zakresie strategii i planowania rozwoju biznesu.</li>
          <li>Chcesz mieć elastyczność w dostosowywaniu usług do bieżących wyzwań i możliwości biznesowych.</li>
        </ul>

        <button
          class="services-button"
          :class="{ mobile: isMobile }"
          @click="selectService('other')"
        >
          Dowiedz się więcej!
        </button>
      </div>
      
     
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  name: 'RemoteServicesComponent',
  data() {
    return {
      isHeaderVisible: false,
      benefits: {
      'Oszczędność czasu:': {
        text:'Odciążę Cię w tematach administracyjnych i analizie kluczowych obszarów Twojej restauracji, pozwalając Ci skupić się na rozwoju biznesu.',
        img: require('./../assets/icons/clock.svg')},
        'Oszczędność kosztów:': {
        text:'Płacisz tylko za wykonaną pracę, bez dodatkowych kosztów zatrudnienia, takich jak szkolenia, świadczenia pracownicze, chorobowe czy urlopy.',
        img: require('./../assets/icons/hand-coins.svg')},
      'Profesjonalne wsparcie:': {
        text:'Posiadam 17 lat doświadczenia w gastronomii i przez 9 lat prowadziłam własny gastrobiznes.',
        img: require('./../assets/icons/handshake.svg')},
      'Więcej czasu na rozwój biznesu:':{
        text:'Skup się na planowaniu, rozwoju nowych produktów i usług oraz na strategii i wdrażaniu kluczowych zmian.',
        img: require('./../assets/icons/chart-line-up.svg')},
      }
      
    }
  },
  mounted() {
    this.observeElement(this.$refs.header, 'isVisible1', this.loadAnimation)
  },
  methods: {
    async selectService(service) {
      await this.$router.push({ name: 'Services', params: { id: service } });
      await this.$store.dispatch('updateSection', 'top')
      await this.$store.dispatch('updateSection', 'defoult')
    },
    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000)
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },

    loadAnimation() {
      const anim = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/circles/05.json'
      })

      anim.setSpeed(2)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.instructions{
  display: grid;
  margin: 2rem;
  margin-left: 4rem;
  max-width: 40rem;
  gap: 1rem;
  grid-template-columns: 3rem auto;
}

.instructions-grid{
  display: grid;
  grid-template-columns: auto auto;
}
.picture{
  max-width: 20rem;
}

.instructions.mobile{
  margin: 0rem;
  margin-left: -1rem;
}

.instructions-header{
  margin-top: 5rem;
}

.instructions-header.mobile{
  margin-top: 6rem;
}



.instruction-img{
  img{
    max-width: 3rem;
    margin-top: 1rem;
  }
}

.instruction-text{

  p,strong{
    text-indent: 0rem;
    
  }
  
}

ul {
  list-style-type: none; 
  padding: 0;
  }
  ul li {
      position: relative;
      padding-left: 1.5em; 
  }
  ul li::before {
      content: "\2713"; 
      position: absolute;
      left: 0;
      font-weight: bold;
  }

.remote-services-container {
  margin-top: 5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.remote-services-container.mobile {
  margin-top: 2rem;
  padding: 1rem;
}

.header-container {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  width: 25rem;
  }

  .header-container.mobile {
    margin-top: 2rem;
    margin-bottom: -3rem;
    margin-left:  -1rem;
    flex-direction: column;
  text-align: center;
  }



.horizontal-circle {
  position: absolute;
  top: 1.5rem;
  left: 3.4rem;
  max-width: 16rem;
  transform: scaleX(1.5);
  z-index: 0;
}

.horizontal-circle.mobile {
  position: absolute;
  top: 2.5rem;
  left: 3.4rem;
  max-width: 16rem;
  transform: scaleX(1.5);
  z-index: 0;
}


.services-cards-container{
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;
}

.services-cards-container.mobile{
  margin-top: 1rem;
  margin-left: -2rem;
  display: grid;
  grid-template-columns: 1fr;
}


.card{
  position: relative;
  margin-bottom: 2rem;
  p{
    text-indent: 0rem;
  }
}

.card.mobile{
  width: 100%;
  p{
    margin-left: 1rem;
  } 

}

.benefits-header{
    
    text-align: center;
    margin-left: 0rem;
  }

.cell{
  padding: 1rem;
}
.benefits-icon{
  margin-top: .5rem;
  width: 4rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}


.content_header {
  margin-left: 1rem;
}

.services-grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  width: 100%;
}

.services-grid.mobile {
  grid-template-columns: 1fr;
}

.service-card {
  position: relative;
  background-color: #f5f5f5;
  background-color: $main-color-light;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0.2rem 0.2rem 0.3rem $gray-light;
  transition: transform 0.3s ease;
  padding-bottom: 8rem;

  &:hover {
    transform: translateY(-5px);
  }

  h5,h6{
    margin-bottom: 1rem;
    color: $gray;
  }

  h5 {
    text-align: center;
  }
  h6 {
    margin-top: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }


  p,li {
    font-size: 1rem;
  }

  li {
    margin-bottom: 1rem;
    text-indent: 0em;
  }
}


.services-container {
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.services-button {
  background-color: $main-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;

  position: absolute;
  bottom: 4rem;
  left: 2.8rem;
  

  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.1;
  }
}

.services-button-menager {
  background-color: $secendary-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $secendary-color-dark;
  border: none;
  cursor: pointer;
  color: $white;

  position: absolute;
  bottom: 4rem;
  left: 2.8rem;
  

  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.1;
  }
}



.menager{
  background-color: $main-color;
  box-shadow: 0.3rem 0.3rem 0.4rem $gray-light;
}

.card-line {
  position: absolute;
  top: 8rem;
  left: 4rem;
  width: calc(100% - 8rem);
 
  height: 3px;
  background-color: $gray;
}

.card-line.mobile {
  top: 8rem;
}

</style>
