<template>
  <div class="contact-container" :class="{ mobile: isMobile }">
    <SubscriptionPopup ref="subscriptionPopup" />
    <div v-if="!isMobile">
      <img
        alt="Zdjęcie Martyny Więckowiak"
        ref="picture"
        class="foto"
        :class="{ mobile: isMobile }"
        src="@/assets/pictures/martynkas-pictures/16c.jpg"
      />
    </div>
    <div ref="info" class="info" :class="{ mobile: isMobile }">
      <div ref="header" class="header-container" :class="{ mobile: isMobile }">
        <div class="circle" ref="lottieContainer"></div>
        <h2 class="content_header">Kontakt</h2>
      </div>

      <div v-if="isMobile">
        <img
          ref="picture"
          class="foto"
          :class="{ mobile: isMobile }"
          src="@/assets/pictures/martynkas-pictures/16c.jpg"
          alt="Martyna Więckowiak"
        />
      </div>

      <div ref="contact">
        <p>
          <span class="bold"
            >Jeśli chcesz się z nami skontakotwać napisz na:
          </span>
        </p>
        <div class="link-container">
          <span class="text-link">kontakt@odstresudogastrobiznesu.pl </span>
        </div>

        <div class="contact-button-container" :class="{ mobile: isMobile }">
          <div>
            <p class="or" :class="{ mobile: isMobile }">
              <span class="bold">Lub: </span>
            </p>
          </div>
          <div class="button-container">
            <button
              @click="openSubscriptionPopup"
              class="contact-button"
              :class="{ mobile: isMobile }"
            >
              Wypełnij formularz kontaktowy
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import SubscriptionPopup from './elements/ContactForm.vue'

export default {
  metaInfo: {
    title: 'Kontakt - Skontaktuj się z nami już dziś!',
    meta: [
      {
        name: 'description',
        content:
          'Masz pytania? Skontaktuj się z nami, aby dowiedzieć się więcej o naszych usługach. Napisz do nas e-mail lub wypełnij formularz kontaktowy na naszej stronie. Odpowiemy na wszystkie Twoje pytania!'
      },
      {
        name: 'keywords',
        content:
          'kontakt, formularz kontaktowy, e-mail kontaktowy, skontaktuj się, wsparcie, serwis klienta'
      }
    ]
  },
  components: {
    SubscriptionPopup
  },
  data() {
    return {
      isHeaderVisible: false
    }
  },
  mounted() {
    this.observeElement(this.$refs.header, 'isVisible1', this.loadAnimation)
    this.setupObserver()
  },
  methods: {
    openSubscriptionPopup() {
      this.$refs.subscriptionPopup.openPopup()
    },
    setupObserver() {
      const alwaysVisibleElement = this.$refs.contact // referencja do elementu, który jest zawsze widoczny
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting && !this.isMobile) {
              this.activateAnimations()
            }
          })
        },
        { threshold: 0.1 }
      )

      observer.observe(alwaysVisibleElement)
    },

    activateAnimations() {
      const elementsToAnimate = [this.$refs.info]

      elementsToAnimate.forEach(el => {
        if (el) {
          el.classList.add('animated')
        }
      })
    },

    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000)
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },

    loadAnimation() {
      const anim = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: '/circles/05.json'
      })

      anim.setSpeed(2)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

.header-container.mobile {
  margin-bottom: 2rem;
}

.link-container {
  justify-content: flex-start;
}

.contact-button-container {
  display: flex;
  margin-top: 1rem;
}

.contact-button-container.mobile {
  display: inline;
  margin-top: 1rem;
}
.contact-button {
  background-color: $main-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;
  margin-left: 1rem;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.05;
  }
}

.contact-button.mobile {
  padding: 0.3rem 1rem;
  margin-left: 0.5rem;
}

.contact-container {
  margin-top: 5rem;
  padding: 0rem;
  display: grid;
  gap: 6rem;
  grid-template-columns: 2fr 3fr;
}

.foto {
  width: 25rem;
  height: 25rem;
  object-fit: cover;
  object-position: 50% 30%;
  border-radius: 5px;
}

.foto.mobile {
  width: 20rem;
  height: 20rem;
  margin-left: 0.5rem;
}

.contact-container.mobile {
  display: flex;
  align-items: center;
  margin-top: 0rem;
}

.info.animated {
  animation: fadeIn 1s forwards 0.5s;
}

p {
  text-indent: 0em;
  margin-top: 2rem;
}
.or {
  margin-top: 0.4em;
}

.info {
  position: relative;
  opacity: 0;
}

.or.mobile {
  margin-top: 2rem;
  text-align: center;
}

.info.mobile {
  opacity: 1;
}
</style>
