<template>
  <div class="courses-container" :class="{ mobile: isMobile }">
    <SubscriptionPopup ref="subscriptionPopup" />
    <div :class="{ mobile: isMobile }" class="info">
      <div ref="header" class="header-container" :class="{ mobile: isMobile }">
        <div class="horizontal-circle" ref="lottieContainer"></div>
        <h2 class="courses-header">Szkolenia Online</h2>
      </div>

      <div class="courses" :class="{ mobile: isMobile }" ref="courses">
        <h5 class="header" :class="{ mobile: isMobile }">
          Obecnie pracujemy nad kursem:
        </h5>

        <p class="text" :class="{ mobile: isMobile }">
          <span class="bold">Zarządzanie Pracownikami</span> <br />Jak
          Rekrutacja i selekcja pracowników, wdrożenie, szkolenie i rozwój,
          motywowanie, bieżące zarządzanie, komunikacja i rola lidera w
          budowanie zaangażowanego zespołu.
        </p>
        <div
          v-if="isMobile"
          ref="picture"
          class="foto-container"
          :class="{ mobile: isMobile }"
        >
          <img
            alt="Zdjęcie Martyny Więckowiak z komputerem"
            class="foto"
            :class="{ mobile: isMobile }"
            src="@/assets/pictures/no-background/08c.png"
          />
        </div>

        <p class="text trailer" :class="{ mobile: isMobile }">
          Więcej informacji już niebawem, a teraz zapisz się na listę
          oczekujących, aby otrzymać wszyskie powiadomienia dotyczące tego
          kursu.
        </p>
      </div>
    </div>

    <div
      v-if="!isMobile"
      ref="picture"
      class="foto-container"
      :class="{ mobile: isMobile }"
    >
      <img
        alt="Zdjęcie Martyny Więckowiak z komputerem"
        class="foto"
        :class="{ mobile: isMobile }"
        src="@/assets/pictures/no-background/08c.png"
      />
    </div>
  </div>

  <div class="button-container">
    <button
      class="courses-button"
      :class="{ mobile: isMobile }"
      @click="openSubscriptionPopup"
    >
      Zapisz się na listę oczekujących!
    </button>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import SubscriptionPopup from './elements/CourseListPopup.vue'

export default {
  name: 'OnlineCoursesContainer',
  metaInfo: {
    title: 'Szkolenia Online - Zapisz się na listę oczekujących!',
    meta: [
      {
        name: 'description',
        content:
          'Dołącz do naszych innowacyjnych szkoleń online. Zapisz się na listę oczekujących, aby otrzymać pierwszeństwo i wszystkie powiadomienia o nadchodzących kursach. Nie przegap okazji do rozwoju w zarządzaniu pracownikami!'
      },
      {
        name: 'keywords',
        content:
          'szkolenia online, zarządzanie pracownikami, kursy online, rozwój zawodowy, kurs zarządzania, edukacja online'
      }
    ]
  },
  emits: ['popup'],
  components: {
    SubscriptionPopup
  },
  data() {
    return {
      isHeaderVisible: false
    }
  },
  mounted() {
    this.setupObserver()
    this.observeElement(this.$refs.header, 'isVisible1', this.loadAnimation)
  },
  methods: {
    openSubscriptionPopup() {
      this.$emit('popup')
    },
    setupObserver() {
      const alwaysVisibleElement = this.$refs.courses // referencja do elementu, który jest zawsze widoczny
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.activateAnimations()
            }
          })
        },
        { threshold: 0.1 }
      )

      observer.observe(alwaysVisibleElement)
    },

    activateAnimations() {
      const elementsToAnimate = [this.$refs.picture]

      elementsToAnimate.forEach(el => {
        if (el) {
          el.classList.add('animated')
        }
      })
    },

    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000)
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },

    loadAnimation() {
      const anim = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: false,
        path: '/circles/04.json'
      })

      anim.setSpeed(2)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';




.courses-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
  gap: 7rem;
  grid-template-columns: 3fr 2fr;
}

.header-container.mobile {
  margin-top: 3rem;
}

.courses-container.mobile {
  display: inline;
}
.courses-header {
  color: $white;
  margin-left: 0rem;
  width: 100%;
}

.courses-header.mobile {
  margin-top: rem;
}

.foto-container {
  position: relative;
  opacity: 0;
  height: 18rem;
}

.foto-container.animated {
  animation: right 1s forwards 0.5s;
  max-width: 100%;
}

.foto-container.mobile {
  height: 22rem;
  width: 100%;
  overflow: hidden;
  display: inline-block;
}

.foto {
  position: absolute;
  height: 33rem;
  bottom: -18rem;
  right: -12rem;
  z-index: -1;
}
.foto.mobile {
  position: absolute;
  height: 20rem;
  bottom: 0;
  left: -2rem;
  margin-right: 0rem;
}
.text {
  text-indent: 0em;
  color: $gray;
  max-width: 29rem;
  z-index: 1;
}

.trailer.mobile {
  margin-top: 5rem;
}

.info {
  position: relative;
}

.courses-button {
  background-color: $secendary-color;
  padding: 0.5rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 2px 2px 2px 0px $secendary-color-dark;
  border: none;
  cursor: pointer;
  color: $white;

  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.1;
  }
}

.courses-button.mobile {
  padding: 0.3rem 1rem;
  margin-top: 2rem;
}
</style>
