<template>
  <div class="description" :class="{ mobile: isMobile }" ref="description">
    <div>
      <div ref="header1" class="header-container" :class="{ mobile: isMobile }">
        <div class="circle" :class="{ mobile: isMobile }" ref="lottieContainer"></div>
        <h2 class="content_header">Dlaczego?</h2>
      </div>

      <p class="text" :class="{ mobile: isMobile }">
        Rozumiemy, jak istotne jest posiadanie wiedzy i narzędzi, które
        umożliwiają osiągnięcie sukcesu w konkurencyjnym środowisku.
      </p>
      <p class="text" :class="{ mobile: isMobile }">
        Wierzymy w rozwój, potencjał ludzki i w to, że każdy przedsiębiorca może
        stworzyć wyjątkową firmę, z której będzie dumny.
      </p>
    </div>

    <div v-if="!isMobile">
      <img alt="Strzałka wskazująca" src="@/assets/elements/arrow03.svg" />
    </div>

    <div class="what">
      <div ref="header2" class="header-container" :class="{ mobile: isMobile }">
        <div class="circle" :class="{ mobile: isMobile }" ref="lottieContainer2"></div>
        <h2 class="content_header">Co robimy?</h2>
      </div>

      <p class="text" :class="{ mobile: isMobile }">
        Pomagamy przedsiębiorcom i managerom z branży gastronomicznej w procesie
        tworzenia i rozwijania ich firm, aby były poukładane, świetnie
        zarządzane i przynosiły zyski.
      </p>
      <p class="text" :class="{ mobile: isMobile }">
        Skupiamy się na kluczowych aspektach takich jak zarządzanie zespołem,
        doskonalenie umiejętności liderów, opracowanie strategii sprzedażowych
        oraz efektywne zarządzanie finansami.
      </p>
    </div>
  </div>
</template>

<script>
import lottie from 'lottie-web'

export default {
  data() {
    return {
      isHeaderVisible: false,
      question: 'why',

      why: 'Rozumiemy, jak istotne jest posiadanie wiedzy i narzędzi, które umożliwiają osiągnięcie sukcesu w konkurencyjnym środowisku. Pomagamy i wspieramy przedsiębiorców, aby ich droga do sukcesu była łatwiejsza i bardziej efektywna. Wierzymy w rozwój, potencjał ludzki i w to, że każdy przedsiębiorca może stworzyć wyjątkową firmę, z której będzie dumny.',
      what: 'Pomagamy przedsiębiorcom i managerom z branży gastronomicznej w procesie tworzenia i rozwijania ich firm, aby były poukładane, świetnie zarządzane i przynosiły zyski. Skupiamy się na kluczowych aspektach takich jak zarządzanie zespołem, doskonalenie umiejętności liderów, opracowanie strategii sprzedażowych oraz efektywne zarządzanie finansami.',
      fadingOut: true,
      text: ''
    }
  },
  mounted() {
    this.observeElement(this.$refs.header1, 'isVisible1', this.loadAnimation)
    this.observeElement(this.$refs.header2, 'isVisible2', this.loadAnimation2)
  },
  methods: {
    observeElement(element, visibilityFlag, animationCallback) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            setTimeout(() => {
              animationCallback.call(this)
            }, 1000) // Uruchomienie animacji po 1 sekundzie
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },

    loadAnimation() {
      const anim = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: false,

        path: '/circles/05.json'
      })

      anim.setSpeed(2)
    },
    loadAnimation2() {
      const anim2 = lottie.loadAnimation({
        container: this.$refs.lottieContainer2,
        renderer: 'svg',
        loop: false,

        path: '/circles/05.json'
      })

      anim2.setSpeed(2)
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';
.header-container{
  margin-left: 4rem;
}
.header-container.mobile{
  margin-left: 2rem;
}
.what {
  margin-top: 10rem;
}
.description {
  display: grid;
  grid-template-columns: 1fr 10rem 1fr;
  gap: 1rem;
}

.description.mobile {
  display: inline;
}

.img-container {
  position: relative;
  margin-top: 5rem;
  width: 75%;
  margin-left: 2rem;
  margin-right: auto;
}

img {
  margin-top: 4rem;
  max-width: 100%;
}

.question {
  position: absolute;
  font-weight: 900;
  bottom: 0rem;
  color: $white;
  background-color: rgba($gray-dark, 0.95);
  padding: 0rem 1rem;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.question.fade-out {
  opacity: 0;
}
.question-button-container {
  position: absolute;
  right: -13rem;
  top: 4rem;
}
.question-button {
  background-color: $gray-light;
  padding: 0.5rem 1rem;
  margin-top: 3rem;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 900;
  font-size: $text-large;
  text-transform: uppercase;
  box-shadow: 1px 1px 0px 0px rgba($gray, 0.95);
  transition: scale 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.05;
  }
}

.active {
  background-color: $main-color;
}

.header-container {
  margin-bottom: 1rem;
}
.horizontal-circle.mobile {
  top: 0.5rem;
  left: 1.5rem;
}

.circle {
  left: -1.2rem;
}

.circle.mobile {
  left: -1.2rem;
}
.descrition-container {
  margin-top: 3rem;
  padding-bottom: 7rem;
  display: grid;
  gap: 6rem;
  grid-template-columns: 1fr 1fr;
}

.foto {
  width: 25rem;
  object-fit: cover;
  border-radius: 5px;
}

.text {
  margin-top: 1rem;
  max-width: 20rem;
  margin-left: 4rem;
  
}

.text.mobile {
  margin-top: 1rem;
  max-width: max-content;
  margin-left: 1rem;
}

.info {
  position: relative;
}
.button-container {
  margin-top: 5rem;
  width: 100%;
  text-align: center;
}

.blog-button {
  background-color: $main-color;
  padding: 0.2rem 1rem;
  border-radius: 2.5rem;

  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  font-size: $text-large;
  box-shadow: 2px 2px 2px 0px $gray;

  border: none;
  cursor: pointer;
  color: $gray;
  z-index: 5;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  a {
    text-decoration: none;
  }
  &:hover {
    scale: 1.1;
  }
}
</style>
