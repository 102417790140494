import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import VueGtag from 'vue-gtag'
import { createHead } from '@vueuse/head'

import BaseSpinner from "./components/ui/BaseSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import BaseCard from "./components/ui/BaseCard.vue";

const app = createApp(App)

const head = createHead()

app.component('LottieAnimation', LottieAnimation)
app.component("base-spinner", BaseSpinner)
app.component("base-dialog", BaseDialog)
app.component("base-card", BaseCard)

app.use(VueGtag, {
  config: { id: 'G-0M1PCXLKNV' } // Twój identyfikator Google Analytics
}, router)

app.use(head)
app.use(store)
app.use(router)

app.mount('#app')