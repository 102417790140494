<template>
  <div ref="hero" class="home-page-container">
    <SubscriptionPopup ref="subscriptionPopup" />
    <hero-component @scroll-to="scrollTo"></hero-component>

    <svg width="0" height="0">
      <defs>
        <clipPath id="wavy-path" clipPathUnits="objectBoundingBox">
          <path d="M0,0.1 Q0.80,0.1 1,0 L1,1 Q0.01,0.95 0,1 Z" />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="wavy-path2" clipPathUnits="objectBoundingBox">
          <path d="M0,0.05 Q0.80,0.1 1,0.05 L1,1 Q0.01,0.95 0,1 Z" />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="wavy-path4" clipPathUnits="objectBoundingBox">
          <path d="M0,0.05 Q0.80,0.05 1,0.05 L1,1 Q0.01,0.95 0,1 Z" />
        </clipPath>
      </defs>
    </svg>
    <svg width="0" height="0">
      <defs>
        <clipPath id="wavy-path3" clipPathUnits="objectBoundingBox">
          <path d="M0,0.05 Q0.80,0.1 1,0.05 L1,1 Q1,1 0,1 Z" />
        </clipPath>
      </defs>
    </svg>
    <div>
      <div ref="description"></div>
      <div class="text_container">
        <description-component></description-component>
      </div>
    </div>
    <div>
      <div class="gray-background2" :class="{ mobile: isMobile }">
        <div ref="remote-services">
          <div class="text_container">
            <remote-services-component></remote-services-component>
          </div>
        </div>
      </div>
    </div>

    <div class="main-background" :class="{ mobile: isMobile }">
      <div ref="courses"></div>
      <div class="text_container">
        <courses-component-short
          @popup="openSubscriptionPopup"
        ></courses-component-short>
      </div>
    </div>

    <div>
      <div ref="podcast"></div>
      <div class="text_container no-background">
        <podcast-component-short></podcast-component-short>
      </div>
    </div>

    <div class="gray-background">
      <div ref="blog"></div>
      <div class="text_container">
        <blog-component-short></blog-component-short>
      </div>
    </div>

    <div>
      <div ref="contact"></div>
      <div class="text_container no-background">
        <contact-component></contact-component>
      </div>
    </div>

    <div>
      <div class="footer-background">
      <div ref="footer"></div>
      <div >
        <footer-component></footer-component>
      </div>
    </div>
    </div>
    <PrivacyPopup />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useHead } from '@vueuse/head'

import HeroComponent from './HeroComponent.vue'
import CoursesComponentShort from './CoursesComponentShort.vue'
import PodcastComponentShort from './PodcastComponentShort.vue'
import BlogComponentShort from './BlogComponentShort.vue'
import DescriptionComponent from './DescriptionComponent.vue'
import ContactComponent from './ContactComponent.vue'
import RemoteServicesComponent from './RemoteServicesComponent.vue'
import SubscriptionPopup from './elements/CourseListPopup.vue'
import FooterComponent from './FooterComponent.vue'
import PrivacyPopup from './elements/PrivacyPopup.vue';

export default {
  setup() {
    useHead({
      title: 'Strona Główna - Odkryj nasze szkolenia, podcasty, i więcej!',
      meta: [
        {
          name: 'description',
          content:
            'Witaj na naszej stronie! Odkryj szkolenia, słuchaj podcastów, czytaj nasze najnowsze artykuły i skontaktuj się z nami. Zdobądź wiedzę i inspiracje, które pomogą Ci w rozwoju osobistym i zawodowym.'
        },
        {
          name: 'keywords',
          content:
            'szkolenia online, podcasty edukacyjne, blogi rozwojowe, kontakt, rozwój osobisty, rozwój zawodowy, zarządzanie personelem, menager gastronomii, maketing w gastronomii, sprzedaż w gastronomii, zarządzanie kosztami w gastronomii, koszty w gastronomii'
        }
      ]
    })
  },
  components: {
    HeroComponent,
    CoursesComponentShort,
    PodcastComponentShort,
    DescriptionComponent,
    ContactComponent,
    BlogComponentShort,
    SubscriptionPopup,
    RemoteServicesComponent,
    FooterComponent,
    PrivacyPopup
},
  name: 'HomePageComponent',

  data() {
    return {
      isCoursesVisible: false,
      isHeader1Visible: false,
      isHeader2Visible: false,
      isHeader3Visible: false,
      isHeader4Visible: false
    }
  },
  mounted() {},

  watch: {
    async 'section.section'(newValue) {
      await this.scrollTo(newValue)
      await this.$emit('closeMenu', true)
    }
  },
  methods: {
    openSubscriptionPopup() {
      this.$refs.subscriptionPopup.openPopup()
    },
    observeElement(element, visibilityFlag) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this[visibilityFlag] = true
            observer.unobserve(element)
          }
        })
      })
      observer.observe(element)
    },
    scrollTo(ref) {
      const behavior = this.isMobile ? 'auto' : 'smooth'
      if (this.$refs[ref] && this.$refs[ref].$el) {
        this.$refs[ref].$el.scrollIntoView({
          behavior: behavior,
          block: 'start'
        })
      } else if (this.$refs[ref]) {
        this.$refs[ref].scrollIntoView({ behavior: behavior, block: 'start' })
      }
    }
  },
  computed: {
    ...mapState(['section']),
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes leftAndBounce {
  from {
    opacity: 0;
    transform: translateX(-130px);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes painting {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

.test {
  background-color: aqua;
}
#logo {
  width: 50rem;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
}

#logo_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.no-background {
  padding-top: 1rem;
}
.text_container {
  max-width: $page-width;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 5rem;
}

.text_container.mobile {
}

.text-container-gray {
  max-width: $page-width;
  margin-left: auto;
  margin-right: auto;
  background-color: $gray-light;
  padding-bottom: 5rem;
}

.main-background {
  padding-top: 8rem;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
  background-color: $main-color;
  background-image: url('@/assets/elements/patterns/05.svg');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 100% 10%;
  clip-path: url(#wavy-path);
}

.main-background.popup {
  clip-path: none;
}

.main-background.mobile {
  background-size: 250%;
  background-position: 50% 10%;
}

.gray-background {
  padding-top: 4rem;
  margin-bottom: 4rem;
  background-color: $white-gray;
  z-index: -1;
  padding-bottom: 8rem;
  clip-path: url(#wavy-path2);
}

.footer-background {
  padding-top: 1rem;
  background-color: $secendary-color-dark;
  z-index: -1;

}

.gray-background2 {
  margin-top: -6rem;
  padding-top: 4rem;
  margin-bottom: -9rem;
  background-color: $white-gray;
  z-index: -1;
  padding-bottom: 16rem;
  clip-path: url(#wavy-path2);
}

.gray-background2.mobile {
  margin-top: 0rem;
  padding-top: 0rem;
  padding-bottom: 16rem;
  clip-path:none;
}

.martynka_none {
  max-height: 30rem;
  float: right;
  padding-top: 0px;
  opacity: 0;
}

.martynka-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  grid-area: picture;
  transform: scaleX(-1);
}
.martynka {
  max-height: 27rem;
  padding-top: 0px;
  opacity: 0;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  margin-right: 2rem;
}

.first-container-table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'paragraph picture'
    'icons picture';
}

#first-container-paragraph {
  grid-area: paragraph;
}

.content_header {
  color: rgb(255, 255, 255);
  background-image: url('~@/assets/elements/painted_background.png');
  background-repeat: no-repeat;
  background-size: 30rem, 10rem;
  opacity: 0;
  animation-name: leftAndBounce;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
}
</style>
