<template>
  <div id="top-pannel" :class="{ hidden: isMenuHidden }">
    <div id="navigation">
      <app-navigation v-if="!isMobile" :isMobile="isMobile"></app-navigation>
    </div>
  </div>

  <div>
    <router-view></router-view>
  </div>

  <div id="mobile-navigation">
    <mobile-menu v-if="isMobile" :isMenuHidden="isMenuHidden"></mobile-menu>
  </div>
</template>

<script>
import AppNavigation from './components/AppNavigation.vue'
import MobileMenu from './components/MobileMenu.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'Od Stresu do Gastrobiznesu',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Strona główna projektu Od Stresu do Gastrobiznesu - szkolenia, podcast i artykuły dla branży gastronomicznej.'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content:
          'gastronomia, restauracja, zarządzanie, szkolenia, podcast, blog, podkast gastronomiczny'
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content: 'Od Stresu do Gastrobiznesu'
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content:
          'Strona główna projektu Od Stresu do Gastrobiznesu - szkolenia, podcast i artykuły dla branży gastronomicznej.'
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: require('@/assets/logo/04ws.png')
      }
    ]
  },
  components: {
    AppNavigation,
    MobileMenu
  },

  data() {
    return {
      title: 'Od Stresu do Gastrobiznesu',
      isMobile: false,
      isMenuHidden: true,
      prevScrollPos: window.pageYOffset,
      section: '',
      scrollThreshold: 200
    }
  },
  watch: {
    $route(to) {
      if (to.path !== '/') {
        this.scrollThreshold = 0
        this.isMenuHidden = false
      } else {
        this.scrollThreshold = 200
        this.isMenuHidden = true
      }
    }
  },

  mounted() {
    if (this.$route.path !== '/') {
      this.scrollThreshold = 0
      this.isMenuHidden = false
    } else {
      this.scrollThreshold = 200
      this.isMenuHidden = true
    }

    this.checkScreenWidth()
    this.setTitle('Od Stresu do Gastrobiznesu')
    window.addEventListener('resize', this.checkScreenWidth)
    window.addEventListener('scroll', this.onWindowScroll)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth)
    window.removeEventListener('scroll', this.onWindowScroll)
  },
  methods: {
    setTitle(newTitle) {
      document.title = newTitle
    },
    checkScreenWidth() {
      this.isMobile = window.innerWidth <= 768
    },
    onWindowScroll() {
      const currentScrollPos = window.pageYOffset
      const isScrollingUp = currentScrollPos < this.prevScrollPos

      if (currentScrollPos < this.scrollThreshold) {
        this.isMenuHidden = true
      } else {
        if (isScrollingUp && this.isMenuHidden) {
          this.isMenuHidden = false
        } else if (
          !isScrollingUp &&
          currentScrollPos > this.scrollThreshold &&
          !this.isMenuHidden
        ) {
          this.isMenuHidden = true
        }
      }

      this.prevScrollPos = currentScrollPos
    }
  }
}
</script>

<style lang="scss">
@import './main.scss';
</style>
