<template>
  <div v-if="isVisible" class="popup"  @click.self="closePopup">
    
    <div  class="popup-content" :class="{ mobile: isMobile }">
      
   
      <button class="close-button" @click="closePopup">&times;</button>
      <div class="popup-header">
      <div class="form-icon-container"><svg xmlns="http://www.w3.org/2000/svg"  fill="#e9e7e7" viewBox="0 0 256 256"><path d="M228.44,89.34l-96-64a8,8,0,0,0-8.88,0l-96,64A8,8,0,0,0,24,96V200a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V96A8,8,0,0,0,228.44,89.34ZM96.72,152,40,192V111.53Zm16.37,8h29.82l56.63,40H56.46Zm46.19-8L216,111.53V192ZM128,41.61l81.91,54.61-67,47.78H113.11l-67-47.78Z"></path></svg></div>
      <h2>Formularz kontaktowy</h2>
        <p>Jeśli masz do mnie jakieś pytania, chcesz nawiązać współpracę, lub masz
        inny powód aby do mnie napisać, wypełnij formularz.</p>
      </div>
      <div class="spinner-container" v-if="isLoading"><base-spinner></base-spinner></div>
      <div v-else-if="message" class="message"><p>{{ this.message }}</p><button  class="ok-button" @click="closeMessage()">ok</button></div>
      <div class="form-container" v-else>
        <form  @submit.prevent="submitForm" class="form-vertical">
        <label>
          <input
            type="email"
            v-model="email"
            name="email"
            placeholder="Wpisz swój email"
            required
          />
        </label>
        <label>
          <textarea
            name="Wiadomość"
            v-model="form_message"
            placeholder="Wiadomość"
            required
          ></textarea>
        </label>
        <button class="submit-button" type="submit">Zapisz się</button>
        
      </form>
      
      </div>
      
    </div>
 
  </div>
</template>

<script>
export default {
  data() {
    return {
      form_message: '',
      isVisible: false,
      email: '',
      name: '',
      isLoading: false,
      message: null,
      success: false,
    }
  },
  methods: {
    closeMessage(){
      if(this.success){
        this.closePopup()
      }
      this.message = null
    },
    openPopup() {
      this.isVisible = true;
    },
    closePopup() {
      this.message = null;
      this.success = false;
      this.isVisible = false;
    },
    submited(){
      this.message = null;
      this.message = "Twoja wiadomość została wysłana! Odpowiem na nią najszybciej jak się da";
      setTimeout(() => {
        this.message = null;
        this.closePopup();
      }, 5000);
      
    },
    async submitForm() {
      this.message = null;
      const formData = new FormData()
      formData.append('Typ', 'Wiadomość')
      formData.append('email', this.email)
      formData.append('wiadomość', this.form_message)
      this.isLoading = true;
      try {
        const response = await fetch('https://formspree.io/f/xjvnjzde', {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json'
          }
        })

        if (response.ok) {
          
          this.email = ''
          this.form_message = ''

          this.$gtag.event('form', {
            'stage': 'send',
            'form': 'contact',
            'success': true
          });
          this.isLoading = false;
          this.success =  true;
          this.submited();
        } else {
          alert('Wystąpił problem z przesłaniem formularza.')

          this.$gtag.event('form', {
            'stage': 'send',
            'form': 'contact',
            'success': false
          })
        }
      } catch (error) {
        console.error('Error:', error)
        alert('Wystąpił błąd podczas przesyłania formularza.')

        this.$gtag.event('form', {
          'stage': 'send',
          'form': 'contact',
          'success': false
        })
      }
    }
  },
  computed: {
    isMobile() {
      return this.$store.state.responsive.isMobile
    }
  },
  mounted() {
    this.$gtag.event('form', {
      'stage': 'enter',
      'form': 'contact'
    });
    this.success = false;
  }
}
</script>

<style scoped lang="scss">
@import '@/main.scss';


.popup-header {
  width: 100%;
  background-color: $white-gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.5rem 0.5rem 0 0;
  border-style: solid;
  border-width: 0 0 3px 0 ;
  border-color: $main-color;
  h2 {
    margin-top: .2rem;
    margin-bottom: 0rem;
 
  }
  p {
    text-indent: 0em;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0.2rem;
    max-width: 25rem;
  }
}

.form-icon-container{
  background-color: $main-color;
  border-radius: 50%;
  padding: 0.3rem;
  margin-top: -2rem;
  width: 2.5rem;
  height: 2.5rem;
  border-style: solid;
  border-color: $gray;
  border-width: 2px;
  svg{
   fill: $gray
  }
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20000;

}

.popup-content {
  background: $white;
  padding: 0rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  
  width: 35rem;
  position: relative;
}

.popup-content.mobile{
  width: 90%;
}


.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;

}

.message {
  position: relative;
  height: 12rem;
  display: flex;
  justify-content: center; 
  text-align: center;

  p {
    font-size: 1.2rem;
    text-align: center;
    text-indent: 0em;
    max-width: 15rem;
  }
}

.ok-button{
  background-color: $main-color;
  padding: 0.3rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 1px 1px 1px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  margin-left: auto;
  margin-right: auto;
  width: 6rem;
  margin-bottom: 1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1rem;
  &:hover {
    scale: 1.05;
  }
}

.spinner-container{
  height: 13rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form-container{
  height: 13rem;
}

.form-vertical {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 60%;

  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
}

.form-vertical label {
  margin-bottom: 1rem;
  font-size: 1rem;
  
}

.form-vertical input[type='email'],
.form-vertical input[name='Imię'] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $gray;
  border-radius: 0.25rem;
  background-color: $white;
}

.form-vertical textarea[name='Wiadomość'] {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid $gray;
  border-radius: 0.25rem;
  background-color: $white;
}

.submit-button {
  background-color: $main-color;
  padding: 0.3rem 1.5rem;
  border-radius: 2.5rem;
  font-family: 'Roboto-Bold';
  text-transform: uppercase;
  box-shadow: 1px 1px 1px 0px $main-color-dark;
  border: none;
  cursor: pointer;
  color: $gray;
  transition: all 0.5s cubic-bezier(0.68, -2, 0.265, 1.55);
  margin-left: auto;
  margin-right: auto;
  max-width: 10rem;
  margin-top: 0.5rem;
  &:hover {
    scale: 1.05;
  }
}
</style>
